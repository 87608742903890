$inter: "Inter";
$sans: sans-serif;
$font-inter: $inter,
$sans;
$suisse_regular: "suisse_intlregular";
$suisse_medium: "suisse_intlmedium";
$font_weight_normal: 400;
$font_weight_middle_bold: 450;
$font_weight_bold: 500;
$color_1: #1a1b25;
$color_2: #36394a;
$color_3: #666d80;
$color_4: #818898;
$color_5: #ffffff;
$color_6: #272835;
$color_7: #0d0d12;
$color_8: #f8fafb;
$color_9: #f6f8fa;
$color_10: #ffffff;
$color_11: #06273b;
$color_12: #c1c6d4;
$color_13: #000000;
$color_14: #d0d1d7;
$color_15: #2c2f3e;
$color_16: #4a9db0;
$color_17: #dfe1e7;
$lh_1: normal;
$bg-1: #ffffff;
$Secondary-Green-25: #ddf3ef;
$Secondary-Green-300: #184e44;
$Secondary-Purple-25: #e5e4fb;
$Secondary-Purple-300: #211d6d;
$lh-2: 1.6rem;
$lh-3: 2rem;
$font_Reg: 400;
$Accent-Aqua-25: #d1f0fa;
$Accent-Aqua-300: #0c4e6e;
$secondary-blue-300: #06273B;
$secondary-blue-25: #EBEFF1;
$Secondary-Red-25: #fadbe1;
$Secondary-Red-300: #710e21;
$Secondary-brown-25: #ffd18f;
$Secondary-brown-300: #744a0d;
$secondary-orange-25: #ffe5da;
$secondary-orange-300: #df5a21;
$Neutral-Alpha-25: #1d4f810a;
$text-loud-900: #0d0d12;
$Toast-BG-Default: rgba(255, 255, 255, 0.95);
$Neutral-Solid-50: #eceff3;
$font_style_1: normal;
$text_1: 1.2rem;
$text_2: 1.4rem;
$text_3: 1.6rem;
$text_4: 1.8rem;
$text_5: 2rem;
$text_7: 2.4rem;
$radius5: 1.2rem;
$shadow-1: 0px 0px 0px 1px #f68c1e,
0px 1px 2px 0px rgba(174, 95, 12, 0.5);
$shadow-2: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
0px 1px 2px 0px rgba(164, 172, 185, 0.24);
$shadow-3: 0px 4px 5px -4px rgba(164, 172, 185, 0.39);
$shadow-4: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
0px 1px 2px 0px rgba(18, 55, 105, 0.08);
$shadow-5: 0px 16px 32px -12px rgba(7, 32, 69, 0.25),
0px 1px 2px 0px rgba(29, 79, 129, 0.04),
0px 0px 0px 1px rgba(18, 55, 105, 0.08);
$shadow-6: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
0px 1px 2px 0px rgba(164, 172, 185, 0.24),
0px 9px 16px -4px rgba(0, 0, 0, 0.13),
0px -7px 10px -5px rgba(0, 0, 0, 0.06) inset;
$shadow-7: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
0px 1px 2px 0px rgba(164, 172, 185, 0.16),
0px 24px 32px -12px rgba(54, 57, 74, 0.24);
$shadow-8: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
0px 1px 2px 0px rgba(29, 79, 129, 0.04),
0px 16px 32px -12px rgba(7, 32, 69, 0.25);
$bg_linear2: linear-gradient(180deg,
  rgba(255, 255, 255, 0) 0%,
  rgba(193, 199, 208, 0.02) 100%),
#ffffff;
$bg_light: rgba(29, 79, 129, 0.04);
$padding-20: 2rem;
$padding-24: 2.4rem;
$border-neutral-solid: #eceff3;
$border-white: #ffffff;
$bg_linear: linear-gradient(180deg,
  rgba(255, 255, 255, 0.12) 0%,
  rgba(255, 255, 255, 0) 100%),
#f68c1e;
$bg_linear2: linear-gradient(180deg,
  rgba(255, 255, 255, 0.12) 0%,
  rgba(255, 255, 255, 0) 100%),
#ffffff;
$bg-linear3: linear-gradient(180deg, #f8fafb 0%, rgba(248, 250, 251, 0) 120%);
$Toast-BG-Default: rgba(255, 255, 255, 0.95);
$bg-proccess: #f68c1e;
$bg-White: #ffffff;
$padding-sm: 0.8rem;
$radius_1: 0.6rem;
$border-bottom-neutral-solid: #eceff3;
$checkedImage: url("../../images/check-right.svg");
$checkedImage2: url("../../images/check-with-shadow.svg");

/* ================================================ */
/*  Universal css for page reset setting start */
/*  ================================================  */
@font-face {
  font-family: "suisse_intlregular";
  src: url("../components/fonts/suisseintl-regular-webfont.woff2") format("woff2"),
    url("../components/fonts/suisseintl-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "suisse_intlmedium";
  src: url("../components/fonts/suisseintl-medium-webfont.woff2") format("woff2"),
    url("../components/fonts/suisseintl-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0rem;
  margin: 0rem;
  box-sizing: border-box;

  &::-moz-selection {
    background: $Accent-Aqua-300;
    color: $bg-White;
  }

  &::selection {
    background: $Accent-Aqua-300;
    color: $bg-White;
  }
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  zoom: 1;
  appearance: none;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
}

body {
  font-family: $font-inter;
  font-size: 16px;
  font-weight: 400;
  color: $color_1;
  background-color: $color_5;
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
}

img {
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
  color: $color_6;
}

.u-select-none {
  user-select: none !important;
}

span.cart-fill {
  padding: 0.4rem;
  border-radius: 5rem;
  color: $color_5;
  box-shadow: $shadow-1;
  position: absolute;
  top: -20%;
  right: -20%;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $bg-proccess;
  font-family: $font-inter;
  font-size: $text_1;
  font-style: normal;
  font-weight: $font_weight_normal;
  line-height: 142.857%;
  z-index: 1;
}

.desktopviewn {
  display: block;
}

.mobviewn {
  display: none;
}

.mb-24 {
  margin-bottom: 2.4rem !important;
}

.btn-light {
  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
    0px 1px 2px 0px rgba(164, 172, 185, 0.24);
  border: none;
}

section {
  padding: 6rem 2.4rem;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  h2 {
    span {
      &.inhh {
        color: #9ba9b1;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
p {
  margin-bottom: 0;
}

.max-width-container {
  max-width: 144rem;
  margin: 0 auto;
  padding: 2.2rem;

  h1 {
    font-family: $suisse_medium;
    letter-spacing: -0.16rem;
    font-size: 8rem;
    font-weight: 500;
    line-height: 8rem;
  }

  h2 {
    font-size: 5.6rem;
    font-family: $suisse_regular;
    line-height: 7.2rem;
  }

  h3 {
    font-size: 4rem;
    font-family: $suisse_regular;
    line-height: 5.6rem;
  }

  h4 {
    font-size: 2rem;
    font-family: $suisse_regular;
  }

  h5 {
    font-size: 1.4rem;
    font-family: $suisse_regular;
  }

  h6 {
    font-size: 1rem;
    font-family: $suisse_regular;
  }
}

p {
  font-size: 1.6rem;
  // font-family: SuisseIntl-Regular;
}

.lh-normal {
  line-height: normal !important;
}

.btn-red {
  border-radius: 6px;
  border: 1px solid var(--Form-Button-Stroke, #fff);
  background: var(--Form-Button-Destructive-Normal,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0) 100%),
      #df1c41);
  box-shadow: 0px 0px 0px 1px #b71836, 0px 1px 2px 0px rgba(113, 14, 33, 0.5);
  padding: 8px 10px;
  color: #fff;
  border: none;

  &:hover {
    background: #df1c41;
    color: #fff;
  }
}

.form-control,
.form-select,
.input-group {
  padding: 0.8rem 1rem;
  background-color: $bg-White;
  box-shadow: $shadow-4;
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
  color: $color_4;
  font-family: $font-inter;
  font-size: $text_2;
  font-style: $font_style_1;
  font-weight: $font_weight_normal;
  line-height: 142.857%;
  letter-spacing: -0.14px;
  text-overflow: ellipsis;

  &::placeholder {
    color: $color_4;
    text-overflow: ellipsis;
  }

  &:focus {
    box-shadow: $shadow-4;
    border-color: #c1c1c1;
  }
}

.form-select {
  background-image: url("../../images/chevron-down.svg");
  background-size: auto;
  color: $color_6;
  padding: 0.8rem 3rem 0.8rem 1rem;

}

.form-select-2 {
  z-index: 1;

  .ng-select-container {
    max-width: 39rem;
    padding: 0.8rem 1rem;
    background-color: $bg-White;
    box-shadow: $shadow-4;
    border-radius: 0.6rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    -ms-border-radius: 0.6rem;
    -o-border-radius: 0.6rem;
    font-family: $font-inter;
    font-size: $text_2;
    font-style: $font_style_1;
    font-weight: $font_weight_normal;
    line-height: 142.857%;
    letter-spacing: -0.14px;
    color: $color_6;

    .ng-value-container {
      padding-left: 0rem;
    }
  }
}

.form-group {

  &.nowrap-select {

    .ng-value-container {
      white-space: nowrap;

      .ng-value-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 27rem;
      }
    }
  }
}

.form-check-input {
  &:focus {
    box-shadow: $shadow-4;
    border-color: #c1c1c1;
  }

  &:checked {
    background-color: $color_11;
    border-color: $color_11;
  }
}

.form-error-text {
  color: red;
  font-size: 1.3rem;
}

.box-shadow {
  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
    0px 1px 2px 0px rgba(164, 172, 185, 0.24);
}

.main-shadow {
  background-color: $color_5;
  box-shadow: $shadow-2;
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
}

.mb-45 {
  margin-bottom: 2.4rem !important;
}

ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.btn-close {
  &:focus {
    box-shadow: none;
  }
}

.bg-light-warning {
  background-color: #fff8f0;
  color: $color_6;
}

.addbutton {
  background: $color_5;
  width: 6.4rem;
  height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
    0px 1px 2px 0px rgba(164, 172, 185, 0.24);

  &:hover {
    background: #f8f8f8;
  }
}

.text-secondary {
  color: $color_3;
}

.text-black {
  color: $color_2;
}

.text-sky {
  color: #219bc3;
}

.minhead {
  letter-spacing: 0.48px;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  grid-gap: 0.8rem;
}

.shopbag-btn {
  background: var(--Form-Button-Secondary-Normal,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(193, 199, 208, 0.02) 100%),
      #fff);
  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
    0px 1px 2px 0px rgba(164, 172, 185, 0.24),
    0px 9px 16px -4px rgba(0, 0, 0, 0.13),
    0px -7px 10px -5px rgba(0, 0, 0, 0.06) inset;
  height: 36px;
  width: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.mobilemenu {
  display: none;
}

.tabview {
  display: none;
}

.searchpopup {
  .modal-content {
    background: #f8fbfa;
    box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25),
      0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04),
      0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
    backdrop-filter: blur(3.743654727935791px);
    border-radius: 1.6rem;
    -webkit-border-radius: 1.6rem;
    -moz-border-radius: 1.6rem;
    -ms-border-radius: 1.6rem;
    -o-border-radius: 1.6rem;
    border: 0rem;

    .modal-body {
      padding: 1.6rem 2.4rem 2.4rem;

      input {
        background-color: $color_5;
        background-image: url("../../images/search.svg");
        background-position: center left 0.8rem;
        background-repeat: no-repeat;
        padding: 0.8rem 1rem 0.8rem 3.2rem;
      }
    }
  }
}

.pageBreadcrumb {
  margin-bottom: 4.8rem;

  .breadcrumb {
    .breadcrumb-item {
      color: $color_6;
      font-size: $text_1;
      font-style: $font_style_1;
      font-weight: $font_weight_bold;
      line-height: 2rem;
      text-transform: capitalize;

      &.active {
        color: #737482;
      }
    }

    .breadcrumb-item+.breadcrumb-item::before {
      float: left;
      padding-right: 0.8rem;
      content: "";
      background-image: url("../../images/arrowright.svg");
      background-position: 10% center;
      background-repeat: no-repeat;
      width: 1.6rem;
      height: 100%;
    }
  }
}

hr.form-hr-gap {
  border-top: 0.1rem solid $border-neutral-solid;
  margin: 2rem 0rem;
  opacity: 1;
}

.backs {
  margin-bottom: 2.4rem;
}

.btn {
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.6rem;
  text-align: center;
  font-size: $text_2;
  font-style: $font_style_1;
  font-weight: $font_weight_bold;
  line-height: 142.857%;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  width: fit-content;

  &:disabled,
  &:active,
  &.active {
    border-color: transparent !important;
  }
}

.btn-primary {
  color: #ffffff;
  border: 0rem;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.12) 0%,
      rgba(255, 255, 255, 0) 100%),
    $color_11;
  box-shadow: 0px 0px 0px 1px $color_11, 0px 1px 2px 0px rgba(3, 24, 37, 0.5);
  column-gap: 0rem;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: $color_11 !important;
  }

  &:disabled {
    background-color: $color_11 !important;
    border-color: $color_11 !important;
    color: #ffffff;
    opacity: 0.4;
    pointer-events: all;
    cursor: no-drop;
  }
}

.btn-danger {
  color: #ffffff;
  border: 0rem;
  background: var(--Form-Button-Destructive-Normal,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0) 100%),
      #df1c41);
  box-shadow: 0rem 0rem 0rem 0.1rem #b71836,
    0rem 0.1rem 0.2rem 0rem rgba(113, 14, 33, 0.5);

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: #b71836;
  }
}

.btn-secondary {
  color: $color_13;
  border: 0rem;
  background: $bg_linear2;
  box-shadow: $shadow-2;
  align-items: flex-end;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: $bg_linear2 !important;
    color: $color_13 !important;
  }

  &:disabled {
    background: $color_8;
    color: #a4acb9;
  }
}

.btn-back {
  color: $color_3;
  letter-spacing: -0.014rem;
  column-gap: 0.8rem;

  span {
    background: $bg_linear2;
    box-shadow: $shadow-2;
    border-radius: 99rem;
    -webkit-border-radius: 99rem;
    -moz-border-radius: 99rem;
    -ms-border-radius: 99rem;
    -o-border-radius: 99rem;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6rem;
  }
}

.btn-secondary-icon {
  color: $color_13;
  border: 0rem;
  background: $bg_linear2;
  box-shadow: $shadow-2;
  border-radius: 99rem;
  -webkit-border-radius: 99rem;
  -moz-border-radius: 99rem;
  -ms-border-radius: 99rem;
  -o-border-radius: 99rem;
  padding: 0.8rem;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: $bg_linear2;
    color: $color_13;
    border-color: transparent;
  }
}

.btn-danger-icon {
  color: #ffffff;
  border: 0rem;
  background: var(--Form-Button-Destructive-Normal,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.12) 0%,
        rgba(255, 255, 255, 0) 100%),
      #df1c41);
  box-shadow: 0rem 0rem 0rem 0.1rem #b71836,
    0rem 0.1rem 0.2rem 0rem rgba(113, 14, 33, 0.5);
  padding: 0.5rem;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: #b71836;
  }
}

.text-warning {
  color: #f68c1e !important;
}

span.badge {
  display: flex;
  padding: 0rem 0.8rem 0rem 0rem;
  justify-content: center;
  align-items: center;
  border-radius: 9.6rem;
  -webkit-border-radius: 9.6rem;
  -moz-border-radius: 9.6rem;
  -ms-border-radius: 9.6rem;
  -o-border-radius: 9.6rem;
  text-align: center;
  font-family: $font-inter;
  font-size: $text_1;
  font-style: $font_style_1;
  font-weight: $font_weight_bold;
  line-height: 133.333%;
  width: fit-content;
}

span.badge.bg-aqua-25 {
  background: $Accent-Aqua-25 !important;
  color: $Accent-Aqua-300 !important;

  svg {
    circle {
      fill: $Accent-Aqua-300 !important;
    }

    path {
      stroke: $Accent-Aqua-300 !important;
    }
  }
}

span.badge.bg-orange-25 {
  background: $secondary-orange-25 !important;
  color: $secondary-orange-300 !important;

  svg {
    circle {
      fill: $secondary-orange-300 !important;
    }

    path {
      stroke: $secondary-orange-300 !important;
    }
  }
}

span.badge.bg-blue-25 {
  background: $secondary-blue-25 !important;
  color: $secondary-blue-300 !important;

  svg {
    circle {
      fill: $secondary-blue-300 !important;
    }

    path {
      stroke: $secondary-blue-300 !important;
    }
  }
}

span.badge.bg-red-25 {
  background: $Secondary-Red-25 !important;
  color: $Secondary-Red-300 !important;

  svg {
    circle {
      fill: $Secondary-Red-300 !important;
    }

    path {
      stroke: $Secondary-Red-300 !important;
    }
  }
}

span.badge.bg-brown-25 {
  background: $Secondary-brown-25 !important;
  color: $Secondary-brown-300 !important;

  svg {
    circle {
      fill: $Secondary-brown-300 !important;
    }

    path {
      stroke: $Secondary-brown-300 !important;
    }
  }
}

span.badge.bg-green-25 {
  background: $Secondary-Green-25 !important;
  color: $Secondary-Green-300 !important;

  svg {
    circle {
      fill: $Secondary-Green-300 !important;
    }

    path {
      stroke: $Secondary-Green-300 !important;
    }
  }
}

span.badge.bg-alpha-25 {
  background: $Neutral-Alpha-25 !important;
  color: $text-loud-900 !important;

  svg {
    circle {
      fill: $text-loud-900 !important;
    }

    path {
      stroke: $text-loud-900 !important;
    }
  }
}

span.badge.bg-purple-25 {
  background: $Secondary-Purple-25 !important;
  color: $Secondary-Purple-300 !important;

  svg {
    circle {
      fill: $Secondary-Purple-300 !important;
    }

    path {
      stroke: $Secondary-Purple-300 !important;
    }
  }
}

.radialProgressBar {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color_17;

  .overlay {
    border-radius: 50%;
    width: 2.7rem;
    height: 2.7rem;
    background: $color_5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    margin: 0rem;
  }

  &.progress-0 {
    background-image: -webkit-linear-gradient(left,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(90deg, $color_17 50%, transparent 50%),
      linear-gradient(90deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-10 {
    background-image: -webkit-linear-gradient(36deg,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(-54deg, $color_17 50%, transparent 50%),
      linear-gradient(270deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-20 {
    background-image: -webkit-linear-gradient(72deg,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(-18deg, $color_17 50%, transparent 50%),
      linear-gradient(270deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-30 {
    background-image: -webkit-linear-gradient(108deg,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(18deg, $color_17 50%, transparent 50%),
      linear-gradient(270deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-40 {
    background-image: -webkit-linear-gradient(36deg,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(54deg, $color_17 50%, transparent 50%),
      linear-gradient(270deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-50 {
    background-image: -webkit-linear-gradient(right,
        $color_17 50%,
        transparent 50%),
      -webkit-linear-gradient(left, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(90deg, $color_17 50%, transparent 50%),
      linear-gradient(270deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-60 {
    background-image: -webkit-linear-gradient(left,
        #f68c1e 50%,
        transparent 50%),
      -webkit-linear-gradient(36deg, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(270deg, #f68c1e 50%, transparent 50%),
      linear-gradient(306deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-70 {
    background-image: -webkit-linear-gradient(left,
        #f68c1e 50%,
        transparent 50%),
      -webkit-linear-gradient(72deg, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(270deg, #f68c1e 50%, transparent 50%),
      linear-gradient(342deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-80 {
    background-image: -webkit-linear-gradient(left,
        #f68c1e 50%,
        transparent 50%),
      -webkit-linear-gradient(108deg, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(270deg, #f68c1e 50%, transparent 50%),
      linear-gradient(18deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-90 {
    background-image: -webkit-linear-gradient(left,
        #f68c1e 50%,
        transparent 50%),
      -webkit-linear-gradient(144deg, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(270deg, #f68c1e 50%, transparent 50%),
      linear-gradient(54deg, #f68c1e 50%, $color_17 50%);
  }

  &.progress-100 {
    background-image: -webkit-linear-gradient(left,
        #f68c1e 50%,
        transparent 50%),
      -webkit-linear-gradient(right, #f68c1e 50%, $color_17 50%);
    background-image: linear-gradient(270deg, #f68c1e 50%, transparent 50%),
      linear-gradient(90deg, #f68c1e 50%, $color_17 50%);
  }
}

.bg-shadow-1 {
  border: 0.1rem solid $border-neutral-solid;
  background: $bg-linear3;
  backdrop-filter: blur(0.25rem);
  border-radius: 999rem;
  padding: 1.2rem;
}

.gap-25 {
  gap: 0.8rem !important;
}

input[type="number"] {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  appearance: textfield;
  -moz-appearance: textfield;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%),
    radial-gradient(36.52% 34.86% at 82.15% 31.8%,
      #ffddba 0%,
      rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);

  .loader-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.8rem;

    @keyframes moving {
      50% {
        width: 100%;
      }

      100% {
        width: 0;
        right: 0;
        left: unset;
      }
    }

    .loader {
      display: block;
      width: 10rem;
      height: 0.3rem;
      border-radius: 3rem;
      -webkit-border-radius: 3rem;
      -moz-border-radius: 3rem;
      -ms-border-radius: 3rem;
      -o-border-radius: 3rem;
      background-color: $color_9;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        background: $color_11;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        border-radius: 3rem;
        -webkit-border-radius: 3rem;
        -moz-border-radius: 3rem;
        -ms-border-radius: 3rem;
        -o-border-radius: 3rem;
        animation: moving 1s ease-in-out infinite;
      }
    }
  }
}

.loader-table-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #8a8a8a3b;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-border {
    width: 7rem;
    height: 7rem;
    border: 0.6rem solid;
    border-right-color: transparent;

    &.text-secondary {
      color: #f68c1e !important;
    }
  }
}

.no-results-content {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .no-result-img {
    width: 25%;

    &.for-admin {
      width: 15%;
    }

    img {
      mix-blend-mode: multiply;
    }
  }

  .title {
    color: $color_6;
    font-family: $suisse_medium;
    font-size: 3.2rem !important;
    font-style: $font_style_1;
    font-weight: $font_weight_bold;
    line-height: 140%;
  }

  .description {
    color: $color_3;
    font-family: $font-inter;
    font-size: 1.8rem;
    line-height: 157%;
    font-style: $font_style_1;
    font-weight: $font_weight_middle_bold;
    letter-spacing: 0.048rem;
    margin-bottom: 0.8rem;
    text-align: center;
  }
}

.product-detail-container {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    z-index: -1;
    background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%),
      radial-gradient(36.52% 34.86% at 82.15% 31.8%,
        #ffddba 0%,
        rgba(255, 255, 255, 0) 100%),
      linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);
  }
}

.qty-input {
  border: 0.1rem solid $border-neutral-solid;
  background: $color_10;
  display: flex;
  align-items: stretch;
  padding: 0.8rem 1.2rem;
  gap: 0.4rem;
  border-radius: 0.8rem;
  -webkit-border-radius: 0.8rem;
  -moz-border-radius: 0.8rem;
  -ms-border-radius: 0.8rem;
  -o-border-radius: 0.8rem;
  width: fit-content;
  height: 4.8rem;

  .product-qty {
    color: $color_6;
    text-align: center;
    font-family: $font-inter;
    font-size: $text_2;
    font-style: $font_style_1;
    font-weight: $font_weight_normal;
    line-height: $lh_1;
    letter-spacing: -0.028rem;
    border: 0rem;
    box-shadow: none;
    max-width: 3.2rem;
    background: transparent;

    &:focus,
    :focus-visible {
      box-shadow: none;
      outline: 0;
    }
  }
}

.sorted {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;

  h5 {
    color: $color_3;
    font-size: $text_2;
    font-style: $font_style_1;
    font-weight: $font_weight_normal;
    line-height: $lh_1;
    margin-bottom: 0rem;
  }

  .dropdown {
    position: relative;

    .btn {
      color: $color_2;
      font-size: $text_2;
      font-style: $font_style_1;
      font-weight: $font_weight_bold;
      line-height: 142.857%;
      padding: 0.8rem 1rem;
      border-radius: 0.6rem;
      -webkit-border-radius: 0.6rem;
      -moz-border-radius: 0.6rem;
      -ms-border-radius: 0.6rem;
      -o-border-radius: 0.6rem;
      display: flex;
      align-items: center;
      column-gap: 0.6rem;
      text-transform: capitalize;

      &::after {
        content: none;
      }

      span {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn[aria-expanded="false"] {
      span {
        svg {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
        }
      }
    }

    .btn[aria-expanded="true"] {
      span {
        svg {
          transform: rotate(-180deg);
          -webkit-transform: rotate(-180deg);
          -moz-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
          -o-transform: rotate(-180deg);
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
        }
      }
    }

    .dropdown-menu {
      background: $Toast-BG-Default;
      box-shadow: $shadow-5;
      backdrop-filter: blur(0.5rem);
      padding: 0.8rem 0rem;
      border: 0rem;
      border-radius: 1rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
      right: 0 !important;
      min-width: 14rem;
      width: 100%;
      z-index: 1035;

      li {
        width: 100%;

        .dropdown-item {
          color: $color_3;
          font-size: $text_1;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 133.333%;
          padding: 0.8rem 1.2rem;
          margin-bottom: 0rem;
          display: block;
          white-space: break-spaces;
          text-transform: capitalize;
          cursor: pointer;

          &:hover,
          &:active {
            color: $color_5;
            background: #f68c1e;
          }
        }
      }
    }
  }
}

.multi-account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0rem;
  gap: 1rem;
  margin-bottom: 2.4rem;

  h5 {
    color: $color_3;
    font-size: $text_2;
    font-style: $font_style_1;
    font-weight: $font_weight_normal;
    line-height: $lh_1;
    margin-bottom: 0rem;
  }

  .dropdown {
    .btn {
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;

      .rotate {
        svg {
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transition: all 0.3s ease-in;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -ms-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
        }
      }

      &.show,
      &:focus {
        border-color: transparent;
      }

      &.show {
        .rotate {
          svg {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
          }
        }
      }

      .bg-loader {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #cbcbcb8f;
        border-radius: inherit;
        display: flex;
        align-items: center;
        justify-content: center;

        .text-primary-2 {
          color: #06273B;
        }
      }
    }

    ul.account {
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      white-space: nowrap;

      li {
        color: $color_3;
        font-family: $font-inter;
        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: 157.143%;
        letter-spacing: -0.014rem;
        width: fit-content;

        .badge {
          color: #5c3d1f;
          background: #faedcc;
          font-size: 1.2rem;
        }
      }
    }

    .dropdown-menu {
      background: #f8fafb;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      padding: 0rem;
      min-width: 22rem;
      width: 100%;
      overflow: hidden;

      .max-h-account {
        max-height: 21.4rem;
        overflow: hidden auto;
        scrollbar-color: $Neutral-Solid-50 $bg-White;
        scrollbar-width: thin;

        &::-webkit-scrollbar-track {
          background-color: $bg-White;
          border-radius: 9rem;
          -webkit-border-radius: 9rem;
          -moz-border-radius: 9rem;
          -ms-border-radius: 9rem;
          -o-border-radius: 9rem;
        }

        &::-webkit-scrollbar {
          background-color: $bg-White;
          width: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $Neutral-Solid-50;
          border-radius: 9rem;
          -webkit-border-radius: 9rem;
          -moz-border-radius: 9rem;
          -ms-border-radius: 9rem;
          -o-border-radius: 9rem;
        }

        ul {
          cursor: pointer;
        }
      }
    }
  }
}

.border-bottom-1 {
  border-bottom: 0.1rem solid $border-neutral-solid;
}

.cart-error {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  background: radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(36.52% 34.86% at 82.15% 31.8%, #ffddba 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);
  border-radius: 999rem;

  svg {
    width: 5rem;
    height: 5rem;
  }

  img {
    animation: tilt 0.5s infinite;
  }

  @keyframes tilt {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-5deg);
    }

    50% {
      transform: rotate(5deg);
    }

    75% {
      transform: rotate(-5deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}

.error-h-100 {
  height: calc(100vh - 26rem);
}

.system-message {

  &.alert {
    font-size: $text_1;
    font-style: $font_style_1;
    font-weight: $font_weight_bold;
    line-height: 1.6rem;
    border-right: 0;
    border-left: 0;
    border-radius: 0rem;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    -ms-border-radius: 0rem;
    -o-border-radius: 0rem;

    .btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      right: 0.5%;
    }
  }
}

.fc-widget-normal.fc-launcher-text {
  left: 15px !important;
  right: auto;
}

.single-post-body {
  padding: 5.2rem 3rem;
  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 0px;
  }

  p{
    margin-bottom: 2rem;
  }
  
  h3 {
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  a{
    color: #0D6efD;
  }
}

@media screen and (min-width: 768px) {

  .w-md-100 {
    width: 100% !important;
  }

}

@media screen and (min-width: 1299px) and (max-width: 1399px) {
  html {
    font-size: 58.5%;
    scroll-behavior: smooth;
  }
}

@media screen and (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .max-w-100 {
    max-width: 100% !important;
  }

  span.cart-fill {
    padding: 0.2rem;
    top: -7%;
    right: -14%;
    min-width: 1.6rem;
    height: 1.6rem;
    font-size: 1rem;
  }

  .no-results-content {

    .no-result-img {

      &.for-admin {
        width: 50%;
      }

    }

    .title {
      font-size: 2.8rem !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .multi-account {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .dropdown {
      width: 100%;

      .dropdown-menu,
      .btn {
        width: 100%;
      }
    }
  }
}

.rc-anchor-normal {
  max-width: 100% !important;
  width: 29.5rem !important;
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    max-height: 20rem !important;
  }
}

.ng-select {
  &.ng-select-opened {
    .ng-select-container {
      z-index: 1 !important;
    }
  }
}

// .ng-dropdown-panel{
//   z-index: 2 !important;
// }